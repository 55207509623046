import { Link } from 'gatsby'
import React from 'react'

const Footer = props => (
  <footer id="footer">

    <section>

      <ul className="icons">
        <li>
          <a target="_blank"
            href="https://twitter.com/janjilecek"
            className="icon fa-twitter alt"
          >
            <span className="label">Twitter</span>
          </a>
        </li>
        <li>
          <a target="_blank" href="https://www.facebook.com/Jungs-Labyrinth-107067494417126" className="icon fa-facebook alt">
            <span className="label">Facebook</span>
          </a>
        </li>

        <li>
          <a target="_blank"
            href="https://github.com/janjilecek"
            className="icon fa-github alt"
          >
            <span className="label">GitHub</span>
          </a>
        </li>

      </ul>
    </section>
    <p className="copyright">
      &copy; Jan Jilecek. Design: <a href="https://html5up.net">HTML5 UP</a>.<br/>Base on HMI by Carol S. Pearson<br/>
    </p>
  </footer>
)

export default Footer
